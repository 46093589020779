import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import CallToAction from "../components/call-to-action"
import { AgentsMeta } from "../data/seo-meta"
import { platformLink } from "../data/external-links"

import londonUWLogo from "../images/logos/london_underwriters.png"
import iscLogo from "../images/logos/isc.png"
import accessOne80 from "../images/logos/access-one80.png"
import sumLogo from "../images/logos/sum_logo.png"
import firstConnectLogo from "../images/logos/first_connect_logo.png"
import phlyLogo from "../images/logos/phly_logo.png"


const distributionPartners = [
  {
    src: londonUWLogo,
    alt: "London Underwriters logo",
  },
  {
    src: sumLogo,
    alt: "Sum logo",
  },
  {
    src: phlyLogo,
    alt: "Philadelphia Insurance Logo",
  },
  {
    src: firstConnectLogo,
    alt: "First Connect logo",
  },
  {
    src: accessOne80,
    alt: "Access One80 logo",
  },
  {
    src: iscLogo,
    alt: "ISC logo",
  }
]

const AgentsPage = () => {
  return (
    <Layout>
      <SEO
        description={AgentsMeta.description}
        path={AgentsMeta.path}
        title={AgentsMeta.title}
      />
      <div className="container py-20 md:py-24">
        <div className="mb-12">
          <h1 className="h1 mb-8">Agents</h1>
          <h3 className="h3 mb-2">
            Experience 100% digital small business insurance.
            <br />
            Seamlessly automated, online, and hassle-free.
          </h3>
        </div>

        <div className="mb-20">
          <CallToAction label="Create your account or log in to access Slice" to={platformLink} />
        </div>

        <h2 className="h2 mb-6">Distribution partners:</h2>
        <p className="p1 mb-8">
          Existing affiliations are shown on agent dashboards.
        </p>
        <div className="flex flex-wrap gap-4 mb-32 justify-center">
          {distributionPartners.map((img) => (
            <div
              className="flex items-center justify-center h-[9rem] w-[18rem] border-[#DEDEDE] rounded-2xl badge-drop-shadow"
              key={img.src}
            >
              <img
                className="max-w-[14rem] max-h-[4.5rem]"
                src={img.src}
                alt={img.alt}
              />
            </div>
          ))}
        </div>

        <h2 className="h2 mb-4">Become a distribution partner.</h2>
        <p className="p1 mb-6 md:max-w-[56rem]">
          We collaborate with MGAs and Wholesalers to distribute our innovative insurance products for small businesses.
        </p>
        <CallToAction label="Get in touch" to="/contact/" />
      </div>
    </Layout>
  )
}

export default AgentsPage
